var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-full-width" }, [
    _vm.url
      ? _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.transformedValue)),
        ])
      : _c("span", [_vm._v(_vm._s(_vm.transformedValue))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }