var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field level-left" }, [
    _c(
      "span",
      { class: { "level-item": _vm.hasSlot } },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checked,
          expression: "checked",
        },
      ],
      staticClass: "switch",
      attrs: {
        type: "checkbox",
        id: _vm._uid,
        "true-value": 100,
        "false-value": 0,
      },
      domProps: {
        checked: Array.isArray(_vm.checked)
          ? _vm._i(_vm.checked, null) > -1
          : _vm._q(_vm.checked, 100),
      },
      on: {
        change: function ($event) {
          var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? 100 : 0
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.checked = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.checked = $$c
          }
        },
      },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm._uid } }, [
      _c("i", { staticClass: "fa fa-check" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }