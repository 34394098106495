var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-form",
      attrs: { visible: _vm.visible },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("p", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.heading)),
      ]),
      _vm._v(" "),
      _c(
        "form",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _vm.enableDropzone
            ? _c(
                "vue-dropzone",
                {
                  ref: "dropzone",
                  attrs: {
                    id: "modal-dropzone",
                    options: _vm.dropzoneOptions,
                    useCustomSlot: "",
                  },
                  on: {
                    "vdropzone-sending": _vm.sendingEvent,
                    "vdropzone-success": _vm.fileSuccess,
                  },
                },
                [
                  _vm._t("default"),
                  _vm._v(" "),
                  _vm.dropZone.type === "image"
                    ? _c("div", { staticClass: "dz-area" }, [
                        _c("span", [
                          _vm._v("Zum Hochladen Datei hier hineinziehen"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._t("default"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button is-cancel",
              on: {
                click: function ($event) {
                  return _vm.$emit("closeModal")
                },
              },
            },
            [_vm._v(_vm._s(_vm.cancelButton))]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "button is-primary", on: { click: _vm.saveForm } },
            [
              !_vm.isLoading
                ? [_vm._v(_vm._s(_vm.saveButton))]
                : _c("i", { staticClass: "material-icons icon-spin" }, [
                    _vm._v("sync"),
                  ]),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }