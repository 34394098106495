<script>
import AsyncSelect from './AsyncSelect';

export default {
    mixins: [ AsyncSelect ],

    props: {
        crudLinkTitle: { type: String, default: 'eine neue Region hinzufügen' }
    },

    placeholder: 'Region wählen',
    api: 'areas'
}
</script>
