import Vuex from 'vuex';
import has from 'lodash/has';
import { getUsers } from '@api';
import debounce from 'lodash/debounce';

const store = new Vuex.Store({
    modules: {
        users: {
            state: {
                items: {},
                isLoading: false
            },

            mutations: {
                updateUsers: function (state, users) {
                    state.items = Object.assign({}, state.items, users);
                },

                setLoading: function (state, newState) {
                    state.isLoading = newState;
                }
            },

            actions: {
                searchUsers: debounce(({ commit, state }, payload = '') => {
                    if (!has(state.items, payload)) {
                        commit('setLoading', true);
                        commit('updateUsers', { '': [] });

                        getUsers('?_search=' + payload).then(response => {
                            commit('updateUsers', { [payload]: response.data });
                            commit('setLoading', false);

                        }, () => {
                            commit('setLoading', false);
                        });
                    }

                }, 400),
            }
        },
    }
});

export { store };
export default store;
