var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-full-width" },
    [
      !_vm.readOnly
        ? _c("input-date", {
            model: {
              value: _vm.internalValue,
              callback: function ($$v) {
                _vm.internalValue = $$v
              },
              expression: "internalValue",
            },
          })
        : _c("span", [_vm._v(_vm._s(_vm.internalValue))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }