var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c("div", { staticClass: "column" }, [_vm._v(_vm._s(_vm.name))]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      !_vm.isEditMode
        ? _c(
            "span",
            { staticClass: "value" },
            [
              _vm.hasMultiple
                ? [
                    _vm._l(_vm.items, function (item, index) {
                      return [
                        index === _vm.items.length - 1
                          ? [_vm._v(_vm._s(item.name))]
                          : [_vm._v(_vm._s(item.name) + ", ")],
                      ]
                    }),
                  ]
                : [_vm.items ? [_vm._v(_vm._s(_vm.items.name))] : _vm._e()],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditMode
        ? _c(
            "div",
            [
              _c("multiselect", {
                attrs: {
                  placeholder: _vm.Suchtext,
                  label: "name",
                  "track-by": "id",
                  options: _vm.listOfItems,
                  multiple: _vm.hasMultiple,
                },
                model: {
                  value: _vm.items,
                  callback: function ($$v) {
                    _vm.items = $$v
                  },
                  expression: "items",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }