var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Aufgabe bearbeiten",
        createTitle: "Neue Aufgabe erstellen",
      },
      on: {
        closeModal: function ($event) {
          _vm.visible = false
        },
        update: _vm.updateTodo,
        create: _vm.createTodo,
      },
    },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loading-container" }, [_c("loading")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "TodoType" } },
                [_vm._v("Kategorie:")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.todoType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "todoType", $$v)
                      },
                      expression: "form.todoType",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "general" } }, [
                      _vm._v("Aufgabe"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "visit" } }, [
                      _vm._v("Kundenbesuch"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "phone" } }, [
                      _vm._v("Telefontermin"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.showOrder
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "order" } },
                    [_vm._v("Reise (optional):")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("order-select", {
                      model: {
                        value: _vm.form.order,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "order", $$v)
                        },
                        expression: "form.order",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasOrder
            ? [
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "deadlineType" } },
                      [_vm._v("Art der Eingabe:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "buttons has-addons toggle-switch" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            class: {
                              "is-primary": _vm.deadlineType === "dueDate",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.deadlineType = "dueDate"
                              },
                            },
                          },
                          [_vm._v("Datum")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            class: {
                              "is-primary": _vm.deadlineType === "deadlineDays",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.deadlineType = "deadlineDays"
                              },
                            },
                          },
                          [_vm._v("Tage vor Reisebeginn")]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.deadlineType === "deadlineDays"
                  ? _c("tr", [
                      _c("th", [
                        _c(
                          "label",
                          {
                            staticClass: "label",
                            attrs: { for: "deadlineDays" },
                          },
                          [_vm._v("Tage vor Reisebeginn:")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.deadlineDays,
                              expression: "deadlineDays",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "input is-small",
                          attrs: { type: "text" },
                          domProps: { value: _vm.deadlineDays },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.deadlineDays = _vm._n($event.target.value)
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasOrder || _vm.deadlineType === "dueDate"
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "dueDate" } },
                    [_vm._v("Deadline:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("input-date", {
                      ref: "dueDate",
                      model: {
                        value: _vm.form.dueDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dueDate", $$v)
                        },
                        expression: "form.dueDate",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "status" } }, [
                _vm._v("Status:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.status,
                        expression: "form.status",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.options.status, function (status) {
                    return _c(
                      "option",
                      { key: status.key, domProps: { value: status.key } },
                      [_vm._v(_vm._s(status.value))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "name" } }, [
                _vm._v("Aufgabe:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "multiselect",
                  {
                    attrs: {
                      options:
                        _vm.form.type === "internal"
                          ? _vm.options.internalNames
                          : _vm.options.externalNames,
                      taggable: true,
                    },
                    on: {
                      tag: _vm.addName,
                      "search-change": _vm.searchName,
                      close: _vm.closeName,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  },
                  [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "type" } }, [
                _vm._v("Art:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "external" } }, [
                      _vm._v("Strenge Deadline"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "internal" } }, [
                      _vm._v("Weiche Deadline"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { staticClass: "is-top" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "description" } },
                [_vm._v("Beschreibung:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.description,
                    expression: "form.description",
                  },
                ],
                staticClass: "textarea",
                domProps: { value: _vm.form.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "description", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "user" } }, [
                _vm._v("User:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("user-select", {
                  model: {
                    value: _vm.form.user,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user", $$v)
                    },
                    expression: "form.user",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "tags" } }, [
                _vm._v("Tags:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "multiselect",
                  {
                    attrs: {
                      options: _vm.options.predefinedTags,
                      taggable: true,
                      "custom-label": _vm.tagLabel,
                      multiple: "",
                    },
                    on: { tag: _vm.addTag },
                    model: {
                      value: _vm.form.tags,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tags", $$v)
                      },
                      expression: "form.tags",
                    },
                  },
                  [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.hasOrder
            ? _c("tr", [
                _c("th", { staticClass: "is-top" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Gültig für:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "div",
                      { staticClass: "checkboxes columns" },
                      [
                        _vm._l(_vm.options.validFor, function (status) {
                          return _c(
                            "div",
                            {
                              key: status.value,
                              staticClass: "column is-one-third",
                            },
                            [
                              _c("label", { staticClass: "checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.validForStatuses,
                                      expression: "form.validForStatuses",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: { status: status.key },
                                    checked: Array.isArray(
                                      _vm.form.validForStatuses
                                    )
                                      ? _vm._i(_vm.form.validForStatuses, {
                                          status: status.key,
                                        }) > -1
                                      : _vm.form.validForStatuses,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.form.validForStatuses,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = { status: status.key },
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.form,
                                              "validForStatuses",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.form,
                                              "validForStatuses",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.form,
                                          "validForStatuses",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(status.value) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.allStatuses,
                                  expression: "allStatuses",
                                },
                              ],
                              attrs: { type: "checkbox", value: "all" },
                              domProps: {
                                checked: Array.isArray(_vm.allStatuses)
                                  ? _vm._i(_vm.allStatuses, "all") > -1
                                  : _vm.allStatuses,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.allStatuses,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "all",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.allStatuses = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.allStatuses = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.allStatuses = $$c
                                    }
                                  },
                                  _vm.toggleCheckboxes,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                alle\n                            "
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }