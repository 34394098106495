var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.search,
        expression: "search",
      },
    ],
    ref: "autocomplete",
    staticClass: "input",
    attrs: { type: "text" },
    domProps: { value: _vm.search },
    on: {
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        $event.preventDefault()
        $event.stopPropagation()
      },
      input: function ($event) {
        if ($event.target.composing) return
        _vm.search = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }