<template>
    <modal visible class="is-form is-image-form" v-on:closeModal="$emit('close')">
        <p slot="header">Neue Google Maps Karte</p>
        <template slot="content">
            <table>
                <map-form v-model="form" v-bind:options="options" />
            </table>
        </template>

        <div class="level" slot="footer">
            <button class="button is-cancel" v-on:click.prevent.stop="$emit('close')">Abbrechen</button>
            <button class="button is-primary" v-on:click.prevent="handleSubmit">Speichern</button>
        </div>
    </modal>
</template>

<script>
import MapForm from '@components/CRUD/Item/Tabs/Images/MapForm';
import Modal from '@components/Modal';
import { googleMapForm } from '@utilities/variables';
import { optionsImages, addGoogleImage } from '@api'
import { prepareForBackend } from '@utilities/functions'

export default {
    props: {
        value: {},
    },

    data: function () {
        return {
            form: JSON.parse(JSON.stringify(googleMapForm)),
            options: {}
        }
    },

    created() {
        optionsImages()
            .then(response => {
                this.options = response.data;
            })
    },

    methods:{
        handleSubmit: function() {
            addGoogleImage(prepareForBackend({
                title: this.form.location,
                type: 'map',
                googleMap: this.form
            }))
                .then(response => {
                    this.addImage(response.data);
                    this.$emit('close');
                })
        },

        addImage: function(image) {
            if (Array.isArray(this.value)) {
                this.$emit('input', [...this.value, image])
            } else {
                this.$emit('input', image)
            }
        }
    },

    components: {
        Modal,
        MapForm
    }
}
</script>

