<template>
    <div class="error-wrapper">
        <div class="control">
            <slot />
        </div>
        <popover v-if="showMessage" trigger="hover" :container="false" class="is-error">
            <i class="material-icons" v-if="showMessage">error</i>
            <template slot="popover">{{message}}</template>
        </popover>
    </div>
</template>

<script>
import Popover from '@components/Popover';

export default {
    props: {
        message: {
            type: String,
            default: ''
        }
    },

    computed: {
        showMessage: function () {
            return this.message !== '';
        }
    },

    components: {
        Popover
    }
}
</script>
