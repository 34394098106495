var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _c("div", { staticClass: "redesign-table list-select" }, [
        _c(
          "div",
          { staticClass: "is-zebra" },
          [
            _vm._l(_vm.rows, function (row) {
              return _c("div", { key: row.id, staticClass: "row" }, [
                _c("div", { staticClass: "cell is-full" }, [
                  _c("label", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selected,
                          expression: "selected",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: row,
                        checked: Array.isArray(_vm.selected)
                          ? _vm._i(_vm.selected, row) > -1
                          : _vm.selected,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.selected,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = row,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selected = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selected = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.customLabel(row)) +
                        "\n                "
                    ),
                  ]),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.rows.length === 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "cell is-full" }, [
                    _vm._v(
                      "Es wurde leider nichts für das Suchkriterum gefunden"
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }