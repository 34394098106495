<template>
    <div class="color-switch">
        <button
            v-for="(option, key) in colorClasses"
            v-bind:key="key"
            v-on:click.prevent.stop="$emit('input', option)"
            class="tag"
            :class="option"
        ></button>
    </div>
</template>

<script>
export default {
    props: {
        colorClasses: {
            type: Array,
            default: () => ['is-green', 'is-red']
        },
    },
}
</script>

