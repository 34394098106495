var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      staticClass: "is-order-select",
      attrs: {
        options: _vm.orders,
        "custom-label": _vm.orderLabel,
        multiple: _vm.multiple,
        loading: _vm.isLoading,
        "internal-search": false,
        "track-by": "id",
        placeholder: _vm.placeholder,
      },
      on: { "search-change": _vm.orderSearch },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function (props) {
            return [
              _c(
                "span",
                {
                  staticClass: "option__id",
                  attrs: { title: "#" + props.option.id },
                },
                [_vm._v("# " + _vm._s(props.option.id))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "option__name",
                  attrs: { title: props.option.client.companyName },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("perm_identity"),
                  ]),
                  _vm._v(" " + _vm._s(props.option.client.companyName) + " "),
                  props.option.groupName
                    ? [_vm._v(" (" + _vm._s(props.option.groupName) + ")")]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "option__destination",
                  attrs: { title: _vm.getDestinations(props.option) },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("flag")]),
                  _vm._v(" " + _vm._s(_vm.getDestinations(props.option))),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "option__date" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("date_range"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("dayMonth")(props.option.startAt)) +
                    " - " +
                    _vm._s(_vm._f("fullYear")(props.option.endAt))
                ),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.orderSelected,
        callback: function ($$v) {
          _vm.orderSelected = $$v
        },
        expression: "orderSelected",
      },
    },
    [
      _vm._v(" "),
      _vm.orders.length === 0
        ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }