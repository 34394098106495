<template>
    <div class="is-full-width">
        <multiselect
            v-if="!readOnly"
            v-model                 = "bankAccount"
            v-bind:options          = "bankAccounts"
            v-bind:internal-search  = "false"
            track-by                = "id"
            label                   = "name"
            placeholder             = "Konto auswählen"
            :allow-empty="false"
            :multiple="false"
        />
        <span style="padding-left: 6px" v-if="readOnly">{{value ? value.name : ''}}</span>
    </div>

</template>



<script>

import Multiselect from './Multiselect';
import {currentUser, getIDFromString} from "@utilities/functions";
import {editUser, getOrganisationBankAccounts} from "@api";
import {notifyError} from "@components/Notification";


export default {

    props: {
        value: {},
        organisationId: {
            type: Number,
            required: false,
        },
        params : {
            type: Object,
            default: () => {}
        },
        selectBankAccount: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        bankAccount: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
    },

    data: function () {
        return {
            bankAccounts: [],
        }
    },

    mounted: function () {
        getOrganisationBankAccounts({...this.params, ...this.organisationId && {'organisation.id': this.organisationId}}).then(response => {
            this.bankAccounts = response.data;
            if(!this.bankAccount && this.selectBankAccount) {
                let defaultBankAccount = this.bankAccounts.filter(item => this.organisationId === getIDFromString(item.organisation));
                defaultBankAccount = defaultBankAccount.length > 0 ? defaultBankAccount : this.bankAccounts;
                this.bankAccount = defaultBankAccount[0];
            }
        }, error => {});
    },

    components: {
        Multiselect
    }
}
</script>
