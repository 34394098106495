var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      attrs: {
        options: _vm.locations,
        loading: _vm.isLoading,
        "custom-label": _vm.locationLabel,
        "internal-search": false,
        placeholder: _vm.placeholder,
        "track-by": "feID",
        "group-values": "items",
        "group-label": "type",
        "select-label": "",
      },
      on: { "search-change": _vm.searchLocations },
      model: {
        value: _vm.locationSelected,
        callback: function ($$v) {
          _vm.locationSelected = $$v
        },
        expression: "locationSelected",
      },
    },
    [
      _vm.isEmptyResults
        ? _c(
            "span",
            { attrs: { slot: "caret" }, slot: "caret" },
            [
              _c(
                "simple-menu",
                {
                  staticClass: "multiselect-crud-link",
                  attrs: { material: "", icon: "add", title: "Menu" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/areas?add",
                        title: "Neuen Region hinzufügen",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v(" Region"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/places?add",
                        title: "Neuen Ort hinzufügen",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v(" Ort"),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasDestination
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "/destinations?add",
                            title: "Neuen Destination hinzufügen",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add"),
                          ]),
                          _vm._v(" Destination"),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }