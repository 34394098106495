<template>
    <input
        ref="autocomplete"
        class="input"
        type="text"
        v-model="search"
        v-on:keydown.enter.prevent.stop=""
    >
</template>

<script>
export default {
    name: 'input-place',

    props: {
        value: {
            type: String,
        }
    },

    mounted: function () {
        this.initAutocomplete();
    },

    computed: {
        search: {
            get: function () { return this.value },
            set: function (value) { this.$emit('input', value )}
        }
    },

    methods: {
        initAutocomplete: function () {
            const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete);
            autocomplete.addListener('place_changed', function () {
                const place = autocomplete.getPlace();
                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()

                this.$emit('place_changed', {lat, lng})
            }.bind(this));

            if (this.value !== '') {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address': this.value
                }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        const place = results[0];
                        const lat = place.geometry.location.lat()
                        const lng = place.geometry.location.lng()

                        this.$emit('place_changed', {lat, lng})
                    }
                }.bind(this));
            }
        },
    }
}
</script>
