<!--
    Used (at least) in orders/placeholders/forms and concepts
-->
<template>
    <multiselect
        v-model="locationSelected"
        v-bind:options         = "locations"
        v-bind:loading         = "isLoading"
        v-bind:custom-label    = "locationLabel"
        v-bind:internal-search = "false"

        :placeholder="placeholder"
        track-by="feID"
        group-values="items"
        group-label="type"
        select-label=""

        v-on:search-change="searchLocations"
    >
        <span slot="caret" v-if="isEmptyResults">
            <simple-menu material
                class = "multiselect-crud-link"
                icon  = "add"
                title = "Menu"
            >
                <a
                    href   = "/areas?add"
                    title  = "Neuen Region hinzufügen"
                    target = "_blank"
                ><i class="material-icons">add</i> Region</a>
                <a
                    href   = "/places?add"
                    title  = "Neuen Ort hinzufügen"
                    target = "_blank"
                ><i class="material-icons">add</i> Ort</a>
                <a
                    v-if   = "hasDestination"
                    href   = "/destinations?add"
                    title  = "Neuen Destination hinzufügen"
                    target = "_blank"
                ><i class="material-icons">add</i> Destination</a>
            </simple-menu>
        </span>
    </multiselect>
</template>



<script>
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import Multiselect from '@components/form/Multiselect';
import SimpleMenu  from '@components/SimpleMenu';
import { getAreas, getPlaces, getDestinations } from '@api';
import { searchByLetters } from '@utilities/functions';
import mixins from "@orders/placeholders/components/forms/mixins";


const emptyObject = { feID: 'null-0', preposition: 'ohne', name: 'Ortsangabe' };


export default {
    mixins: [mixins],
    props: {
        value:               { required: true },
        isNullable:          { type: Boolean, default: false },
        hasDestination:      { type: Boolean, default: false },
        limitedDestinations: { type: Array,   default: () => [] },
        places:              { type: Boolean, default: true },
        areas:               { type: Boolean, default: true },
        placeholder:         { type: String, default: 'tippen, um Standort zu finden' },
    },


    computed: {
        locationSelected: {
            get: function () {
                if (this.value.area) {
                    return Object.assign({}, this.value.area,        { feID: 'area-'        + this.value.area.id });
                }

                if (this.value.place) {
                    return Object.assign({}, this.value.place,       { feID: 'place-'       + this.value.place.id });
                }

                if (this.value.destination) {
                    return Object.assign({}, this.value.destination, { feID: 'destination-' + this.value.destination.id });
                }

                if (this.isNullable) { return emptyObject; }
                return null;
            },

            set: function (location) {
                this.$emit('input', {
                    area:        location && location.feID.indexOf('area-')        !== -1 ? omit(location, ['feID']) : null,
                    place:       location && location.feID.indexOf('place-')       !== -1 ? omit(location, ['feID']) : null,
                    destination: location && location.feID.indexOf('destination-') !== -1 ? omit(location, ['feID']) : null
                });
            }
        }
    },


    data: function () {
        return {
            locations: [
                {
                    type: 'Kein Ort',
                    items: []
                },
                {
                    type: 'Regionen',
                    items: []
                },
                {
                    type: 'Orte',
                    items: []
                },
                {
                    type: 'Destinationen',
                    items: []
                }
            ],
            searchQuery:    '',
            isLoading:      false,
            isEmptyResults: false
        };
    },


    methods: {
        locationLabel: function ({ preposition, name }) { return preposition ? preposition + ' ' + name : name; },

        addLocations: function (places= [], areas= [], destinations = []) {
            this.locations[1].items=  areas.map(item => ({...item, feID: 'area-' + item.id }));
            this.locations[2].items = places.map(item => ({...item, feID: 'place-' + item.id }));
            this.locations[3].items = destinations.map(item => ({...item, feID: 'destination-' + item.id }));
        },
        searchLocations: debounce(function (query) {
            if (query) {
                this.searchQuery = query;
                this.isLoading   = true;

                let calls    = [],
                    apiQuery = {
                        area:        '?_search=' + query,
                        place:       '?_search=' + query,
                        destination: '?_search=' + query
                    };

                if (this.limitedDestinations.length > 0) {
                    apiQuery.area  += this.limitedDestinations.reduce((currentString, destination) =>
                                        (currentString + '&destination.id[]='      + destination.id), '');
                    apiQuery.place += this.limitedDestinations.reduce((currentString, destination) =>
                                        (currentString + '&area.destination.id[]=' + destination.id), '');
                }
                this.callCancelRequest();

                if (this.areas) {
                    calls.push(getAreas(apiQuery.area, { cancelToken: this.cancelSource.token }));
                } else {
                    calls.push(new Promise((resolve) => resolve({data: []})))
                }

                if (this.places) {
                    calls.push(getPlaces(apiQuery.place, { cancelToken: this.cancelSource.token }));
                } else {
                    calls.push(new Promise((resolve) => resolve({data: []})))
                }

                if (this.limitedDestinations.length === 0) {
                    calls.push(getDestinations(apiQuery.destination, { cancelToken: this.cancelSource.token }));
                }

                Promise.all(calls).then(responses => {
                    this.locations[1].items  = responses[0].data
                                                    .map( item => Object.assign(item, { feID: 'area-'  + item.id }));

                    this.locations[2].items     = responses[1].data
                                                    .map( item => Object.assign(item, { feID: 'place-' + item.id }));

                    if (this.hasDestination) {
                        if (this.limitedDestinations.length > 0) {
                            this.locations[3].items = this.limitedDestinations
                                                    .filter(destination => searchByLetters(query, destination.name))
                                                    .map( item => Object.assign(item, { feID: 'destination-' + item.id }));
                        } else {
                            this.locations[3].items = responses[2].data
                                                    .map( item => Object.assign(item, { feID: 'destination-' + item.id }));
                        }
                    }

                    this.isEmptyResults = this.locations[1].items.length === 0 &&
                                          this.locations[2].items.length === 0 &&
                                          (this.hasDestination && this.locations[3].items.length === 0 || !this.hasDestination);

                    this.isLoading = false;
                });
            }
        }, 900)
    },


    created: function () {
        if (this.isNullable) {
            this.locations[0].items.push(emptyObject);
        }
    },


    watch: {
        limitedDestinations: {
            handler: function (newValue, oldValue) {
                if (this.isNullable) {
                    this.locationSelected = emptyObject;

                } else {
                    this.locationSelected = null;
                }

                this.searchLocations(this.searchQuery);
            },
            deep: true
        }
    },


    components: {
        Multiselect,
        SimpleMenu
    }
}
</script>
