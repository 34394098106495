var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.multiple && _vm.value
      ? _c(
          "div",
          { staticClass: "columns is-multiline mb-0 mt-0" },
          _vm._l(_vm.value, function (image) {
            return _c(
              "div",
              { key: image.key, staticClass: "column is-one-third" },
              [
                _c("div", { staticClass: "is-relative" }, [
                  _c(
                    "a",
                    {
                      staticClass: "image",
                      attrs: { href: image.publicPath, target: "_blank" },
                    },
                    [
                      _c("img", {
                        attrs: { src: image.publicPath, alt: image.title },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons image-delete",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.removeImage(image)
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "level-left" }, [
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", name: "user_file_upload" },
        on: {
          change: function ($event) {
            return _vm.onFileChange($event.target.name, $event.target.files)
          },
        },
      }),
      _vm._v(" "),
      !_vm.multiple && _vm.value
        ? _c(
            "a",
            {
              staticClass: "level-item image is-64x64",
              attrs: { href: _vm.value.publicPath, target: "_blank" },
            },
            [
              _c("img", {
                attrs: { src: _vm.value.publicPath, alt: _vm.value.title },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons has-addons" },
        [
          _vm._t("buttons"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.launchFilePicker()
                },
              },
            },
            [
              _vm._t("label", function () {
                return [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("photo")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.label) +
                      "\n                "
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }