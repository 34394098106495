<template>
    <div class="buttons">
        <button 
            v-for="n in 12"
            v-bind:key="n"
            class="button"
            :class="{'is-primary': isSelected(n)}"
            v-on:click.prevent.stop="selectMonth(n)"
        >{{n}}</button>
    </div>
</template>

<script>
export default {
    props: {
        value: {},

        multiple: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        months: {
            get: function () {
                if (this.multiple) {
                    return !!this.value ? this.value : []
                } else {
                    return this.value
                }
            },

            set: function (value) {
                this.$emit('input', value)
            }
        }
    },

    methods: {
        selectMonth: function (number) {
            if (this.multiple) {
                if (this.isSelected(number)) {
                    this.months = this.months.filter(n => n !== number)
                } else {
                    this.months = [...this.months, number]
                }
            } else {
                if (this.isSelected(number)) {
                    this.months = null
                } else {
                    this.months = number;
                }
            }
        },

        isSelected: function (number) {
            if (this.multiple) {
                return this.months.includes(number)
            } else {
                return this.months === number
            }
        }
    }
}
</script>
