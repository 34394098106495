<template>
    <div class="map" v-if="showMap"></div>
</template>

<script>
export default {
    props: {
        lat: Number,
        lng: Number,
        lat2: Number,
        lng2: Number,
        centerLat: Number,
        centerLng: Number,
        zoom: {
            type: Number,
            default: 10,
        },

        styles: {
            type: Array
        }
    },

    data: function () {
        return {
            map: null,
            marker: null,
            endMarker: null
        }
    },

    computed: {
        centerLatLng: function () {
            return {lat: this.centerLat, lng: this.centerLng};
        },

        latLng: function () {
            return {lat: this.lat, lng: this.lng};
        },

        endLatLng: function () {
            return {lat: this.lat2, lng: this.lng2}
        },

        showMap: function () {
            return !!this.lat && !!this.lng
        }
    },

    methods: {
        initMap: function () {
            if (!this.map) {
                const map = new google.maps.Map(this.$el, {
                    zoom: this.zoom,
                    controlSize: 24,
                    styles: this.styles
                });

                this.map = map;
                this.setCenter(map);

                google.maps.event.addListener(map, 'zoom_changed', function () {
                    this.$emit('zoom', map.getZoom())
                }.bind(this))

                google.maps.event.addListener(map, 'center_changed', function () {
                    const center = map.getCenter()
                    this.$emit('pan', {lat: center.lat(), lng: center.lng()})
                }.bind(this))
            }
        },

        setCenter: function () {
            const map = this.map;

            if (map) {
                if (this.centerLat && this.centerLng) {
                    map.setCenter(this.centerLatLng)
                    map.setZoom(this.zoom);
                }

                if (this.lat && this.lng) {
                    this.setMarker();
                }

                if (this.lat2 && this.lng2) {
                    this.setEndMarker();
                }
            }
        },

        setMarker: function () {
            if (this.marker) {
                this.marker.setPosition(this.latLng)
            } else {
                const marker = new google.maps.Marker({
                    position: this.latLng,
                    map: this.map,
                    draggable: true,
                });
                this.marker = marker;

                google.maps.event.addListener(marker, 'dragend', function () {
                    const center = marker.getPosition()
                    this.$emit('position', {lat: center.lat(), lng: center.lng()})
                }.bind(this))
            }
        },

        setEndMarker: function () {
            if (this.endMarker) {
                this.endMarker.setPosition(this.endLatLng)
            } else {
                const marker = new google.maps.Marker({
                    position: this.endLatLng,
                    map: this.map,
                    draggable: true,
                });
                this.endMarker = marker;

                google.maps.event.addListener(marker, 'dragend', function () {
                    const center = marker.getPosition()
                    this.$emit('position', {lat2: center.lat(), lng2: center.lng()})
                }.bind(this))
            }
        }
    },

    watch: {
        showMap: {
            immediate: true,
            handler: function (newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.initMap();
                    })
                }
            }
        },

        endLatLng: {
            deep: true,
            handler: function () {
                this.setEndMarker();
            }
        }
    }
}
</script>

