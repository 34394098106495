var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _vm._m(0),
    _vm._v(" "),
    _c("td", [
      _c("label", { staticClass: "radio" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.importChecked,
              expression: "importChecked",
            },
          ],
          attrs: { type: "radio" },
          domProps: { value: true, checked: _vm._q(_vm.importChecked, true) },
          on: {
            change: function ($event) {
              _vm.importChecked = true
            },
          },
        }),
        _vm._v(" ja\n        "),
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "radio" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.importChecked,
              expression: "importChecked",
            },
          ],
          attrs: { type: "radio" },
          domProps: { value: false, checked: _vm._q(_vm.importChecked, false) },
          on: {
            change: function ($event) {
              _vm.importChecked = false
            },
          },
        }),
        _vm._v(" nein\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("Übersetzungen:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }