<!--
    <entity-select
        v-if="isDataReady"
        v-bind:initialValue="place.destination"
        v-bind:isEditMode="isEditMode"
        v-bind:hasMultiple="true"
        name="Destination"
        namePlural="destinations"
        searchText="Finde eine Destination"
        v-on:update="updateDestination"
    ></entity-select>
-->


<template>
    <div class="columns">
        <div class="column">{{ name }}</div>
        <div class="column">
            <span class="value" v-if="!isEditMode">
                <template v-if="hasMultiple">
                    <template v-for="(item, index) in items">
                        <template v-if="index === items.length - 1">{{ item.name }}</template>
                        <template v-else>{{ item.name }}, </template>
                    </template>
                </template>

                <template v-else>
                    <template v-if="items">{{ items.name }}</template>
                </template>
            </span>

            <div v-if="isEditMode">
                <multiselect
                    v-model="items"
                    v-bind:placeholder="Suchtext"
                    label="name"
                    track-by="id"
                    v-bind:options="listOfItems"
                    v-bind:multiple="hasMultiple"
                ></multiselect>
            </div>
        </div>
    </div>
</template>



<script>
import axios       from 'axios';
import Multiselect from '@components/form/Multiselect';


export default {
    props: {
        initialValue: null,

        isEditMode: {
            type: Boolean,
            default: false
        },

        name: {
            type: String,
            required: true
        },

        namePlural: {
            type: String,
            required: true
        },

        searchText: {
            type: String,
            required: true
        },

        hasMultiple: {
            type: Boolean,
            default: false
        }
    },


    data: function () {
        return {
            items: this.initialValue,
            listOfItems: []
        }
    },


    mounted: function () {
        axios.get('/api/' + this.namePlural).then(response => {
            this.listOfItems = response.data;
        });
    },


    watch: {
        // Once the user chose an item we notify the parent send a special string
        items: function () {
            this.$emit('update', this.items);
        },

        // In the case of cancel we want to use the original value provided
        isEditMode: function () {
            if (!this.isEditMode) {
                this.items = this.initialValue;
            }
        }
    },


    components: {
        Multiselect
    }
}
</script>
