<template>
    <div class="is-full-width">
        <input-date v-model="internalValue" v-if="!readOnly"></input-date>
        <span v-else>{{internalValue}}</span>
    </div>


</template>

<script>


import { InputDate } from '@components/form';

export default {
    props: {
        value: { required: true },
        readOnly: { type: Boolean, default: false},

    },

    computed: {
        internalValue: {
            get: function() {
                return this.value
            },
            set: function (value) {
                this.$emit('input', value)
            }
        },


    },
    components: {
        InputDate
    }

}
</script>
