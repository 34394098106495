/* eslint-disable class-methods-use-this */
import { Mark } from 'tiptap';
import { toggleMark } from 'tiptap-commands';

export default class TextColor extends Mark {
    get name() {
        return 'textcolor';
    }

    get schema() {
        return {
            attrs: {
                color: {
                    default: 'rgba(0,0,0,1)',
                },
            },

            parseDOM: [{
                style: 'color',
                getAttrs: value => ({ color: value })
            }],

            toDOM: mark => ['span', { style:`color: ${mark.attrs.color}` }, 0],
        };
    }

    commands({ type }) {
        return (attrs) => toggleMark(type, attrs)
    }
}
