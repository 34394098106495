<template>
    <div class="is-full-width">
        <a :href="url" v-if="url" target="_blank">{{transformedValue}}</a>
        <span v-else>{{transformedValue}}</span>
    </div>
</template>



<script>


import {fullYear} from "@utilities/functions";

export default {
    props: {
        value: { required: true },
        isDate: {default: false},
        baseUrl: {default: ''},
        urlId: { default: ''}
    },

    computed: {
        url: function () {
            return this.baseUrl + this.urlId
        },

        transformedValue: function () {
            if(this.isDate && this.value){
                return fullYear(this.value)
            }
            return this.value ? this.value : '';
        }
    }

}
</script>
