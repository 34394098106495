var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-form is-image-form",
      attrs: { visible: "" },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Neue Google Maps Karte"),
      ]),
      _vm._v(" "),
      _c("template", { slot: "content" }, [
        _c(
          "table",
          [
            _c("map-form", {
              attrs: { options: _vm.options },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button is-cancel",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Abbrechen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v("Speichern")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }