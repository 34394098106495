<script>
import AsyncSelect from './AsyncSelect';

export default {
    mixins: [ AsyncSelect ],


    props: {
        crudLinkTitle: { type: String, default: 'eine neue Destination hinzufügen' }
    },


    placeholder: 'Destination wählen',
    api:         'destinations'

}
</script>
