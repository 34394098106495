var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("datepicker", {
    ref: "timePicker",
    attrs: {
      config: _vm.config,
      value: _vm.wantedValue,
      placeholder: _vm.placeholderText,
    },
    on: { input: _vm.update },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }