var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      attrs: {
        "track-by": "id",
        "custom-label": (user) => user.firstName + " " + user.lastName,
        options: _vm.users,
        loading: _vm.isLoading,
        "internal-search": false,
      },
      on: {
        "search-change": _vm.searchUsers,
        input: (user) => {
          _vm.$emit("input", user)
        },
        open: _vm.onOpen,
      },
      model: {
        value: _vm.user,
        callback: function ($$v) {
          _vm.user = $$v
        },
        expression: "user",
      },
    },
    [
      _vm.users.length === 0
        ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }