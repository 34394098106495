<template>
    <div class="level level-left" :style="isGapLess ? 'display:webkit-box;': ''">
        <multiselect
            :class="isGapLess ? 'is-gapless' : ''"
            class="level-item"
            :style="!isGapLess ? 'width: 190px' : ''"
            v-model                 = "checkNumber"
            v-bind:options          = "checkNumbers"
            v-bind:internal-search  = "false"
            placeholder             = ""
            :allow-empty="false"
            v-on:tag="handleTag"
            :taggable="true"
            :multiple="false"
        >
            <span slot="caret" v-if="isGapLess"></span>
        </multiselect>
        <button v-if="showPrint"
        class="level-item btn-edit" @click.stop.preven="print"
        ><i class="material-icons is-pointer">print</i></button>
    </div>

</template>



<script>

import Multiselect from './Multiselect';
import {getCheckBook} from "@api";


export default {

    props: {
        value: {},
        isGapLess: {
            default: false,
        },
        bankAccount: {},
        showPrint: {
            default: true,
        }
    },

    methods: {
        handleTag: function(tag) {
            this.checkNumber = tag;
        },
        print: function() {
            //Seems to be best choice: https://github.com/Power-kxLee/vue-print-nb
            //alternative: https://github.com/mycurelabs/vue-html-to-paper


            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html><html><head><style>@page { size: auto;  margin: 0mm; }</style></head><body>${this.checkNumber}</body></html>`);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
    },

    computed: {
        checkNumber: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
    },

    data: function () {
        return {
            checkNumbers: [],
        }
    },

    mounted: function () {
        const user = JSON.parse(localStorage.getItem('user'));

        if(user && user.checkBook) {
            getCheckBook(user.checkBook.id).then(response => {
                let number = Number(response.data.currentNumber) + 1;
                this.checkNumbers.push(number.toString())
            })
        }
    },

    components: {
        Multiselect
    }
}
</script>
