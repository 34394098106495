<template>
    <div class="input-price field has-addons" v-on:click="focus" v-on:keyup.tab="focus" v-if="!readOnly" :class="{'has-suffix': suffix}">
        <div class="control" v-if="showPrefix && !suffix">
            <div class="button is-static" v-if="hasPrefix">{{ prefix }}</div>
            <slot name="prefix"></slot>
        </div>
        <div class="control is-expanded">
            <input
                class="input"
                type="text"
                ref="input"
                v-bind:value="localPrice"
                v-on:blur="updatePrice"
                v-on:focus="focus"
                v-bind:class="inputClass"
                v-bind:disabled="disabled"
                v-bind:placeholder="placeholder"
                :readonly="inputReadOnly"
            />
        </div>
        <div class="control" v-if="showPrefix && suffix">
            <div class="button is-static" v-if="hasPrefix">{{ prefix }}</div>
            <slot name="prefix"></slot>
        </div>
    </div>
    <span :data-prefix="prefix" v-else>{{value | priceView}}</span>
</template>



<script>
import { priceView } from '@utilities/functions';


export default {
    props: {
        value: { required: true },

        prefix: {
            type: String,
            default: "€"
        },

        suffix: {
            type: Boolean,
            default: false,
        },

        inputClass: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        digits: {
            default: 2
        },

        disabled: { type: Boolean, default: false },
        nullable: { type: Boolean, default: false },

        readOnly: { type: Boolean, default: false},

        inputReadOnly: { type: Boolean, default: false}
    },


    computed: {
        transformedValue: function () { return parseFloat(String(this.value).replace(',', '.')).toFixed(this.digits) },
        showPrefix: function () { return !!this.$slots.prefix || this.hasPrefix; },
        hasPrefix:  function () { return !!this.prefix }
    },


    data: function () {
        return {
            localPrice: (!!this.value || this.value === 0) ? this.fromAppToView(this.value, this.digits) : ''
        }
    },


    methods: {
        updatePrice: function (event) {
            let price = event.target.value;

            if (this.fromViewToApp(price, this.digits) !== this.transformedValue) {
                this.localPrice =  this.fromViewToView(price, this.digits);
                if(this.nullable && this.localPrice === '') {
                    this.localPrice = null;
                }

                if (!(this.value === "Infinity" && price === "∞")) {
                    if (isNaN(this.fromViewToApp(this.localPrice))) {
                        if (this.nullable) {
                            this.$emit('input', null);
                            return;

                        } else {
                            this.localPrice = '0.00';
                        }
                    }

                    const inputPrice = this.fromViewToApp(this.localPrice);
                    this.$emit('input',  inputPrice);
                    this.$emit('change', inputPrice);
                }
            }
        },


        focus: function () {
            if (this.$refs.input) {
                this.$refs.input.select();
            }
        },


        // '10000.23' => '10.000,23' - german way
        fromAppToView: function (price, digits) {
            return priceView(price, digits);
        },


        // '10000,2' => '10.000,20' - german way
        fromViewToView: function (price, digits = 2) {
            let number = price.replace('.', '').replace(',', '.');
            return priceView(number, digits);
        },


        // '10.000,20' => '10000.20' - app standard
        fromViewToApp: function (price) {
            if(!price){
                return price;
            }
            let split    = price.split(','),
                decimals = split[1],
                units    = split[0];

            // Removing thousands sign
            units = units.replace('.', '');

            return units + (decimals ? '.' + decimals : '');
        }
    },


    watch: {
        value: function () {
            if (this.value === null) {
                this.localPrice = this.nullable ? null : '0.00';

            } else {
                if (this.transformedValue !== this.fromViewToApp(this.localPrice)) {
                    this.localPrice = this.fromAppToView(this.transformedValue, this.digits);
                }

                if (document.activeElement === this.$refs.input) {
                    this.$nextTick(() => {
                        this.focus();
                    });
                }
            }
        }
    },

    filters: {
        priceView
    }
}
</script>
