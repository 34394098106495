var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _c("tr", [
        _c("th", [
          _c(
            "label",
            { staticClass: "label" },
            [_vm.isRouteMap ? [_vm._v("Start:")] : [_vm._v("Suchen:")]],
            2
          ),
        ]),
        _vm._v(" "),
        _c("td", [
          _c("div", { staticClass: "level level-left" }, [
            _c(
              "div",
              { staticClass: "level-item control" },
              [
                _c("input-place", {
                  on: { place_changed: _vm.updateGoogleMap },
                  model: {
                    value: _vm.location,
                    callback: function ($$v) {
                      _vm.location = $$v
                    },
                    expression: "location",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isRouteMap
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons is-pointer",
                    on: {
                      click: function ($event) {
                        _vm.isRouteMap = true
                      },
                    },
                  },
                  [_vm._v("add_circle")]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isRouteMap
        ? [
            _c("tr", [
              _vm._m(0),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "level level-left" }, [
                  _c(
                    "div",
                    { staticClass: "level-item control" },
                    [
                      _c("input-place", {
                        on: { place_changed: _vm.updateRouteCoordinates },
                        model: {
                          value: _vm.location2,
                          callback: function ($$v) {
                            _vm.location2 = $$v
                          },
                          expression: "location2",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons is-pointer",
                      on: { click: _vm.deleteRouteCoordinates },
                    },
                    [_vm._v("delete")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "toggle-switch",
                    {
                      model: {
                        value: _vm.mode,
                        callback: function ($$v) {
                          _vm.mode = $$v
                        },
                        expression: "mode",
                      },
                    },
                    _vm._l(_vm.modes, function (mode) {
                      return _c(
                        "option",
                        { key: mode.key, domProps: { value: mode.key } },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v(_vm._s(mode.icon)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("tr", [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "td",
          [
            _c(
              "map-preview",
              _vm._b(
                {
                  ref: "map",
                  on: {
                    zoom: (value) => (_vm.zoom = value),
                    pan: _vm.handlePan,
                    position: _vm.handlePosition,
                  },
                },
                "map-preview",
                _vm.googleMapData,
                false
              )
            ),
            _vm._v(" "),
            _vm.isRouteMap
              ? _c("span", { staticClass: "help" }, [
                  _vm._v(
                    "Die Verbindung zwischen den beiden Orten wird erst beim Speichern generiert"
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_c("label", { staticClass: "label" }, [_vm._v("Ziel:")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_c("label", { staticClass: "label" }, [_vm._v("Mode:")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "is-top" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Preview:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }