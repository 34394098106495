<template>
    <loading v-if="isLoading" />
    <div v-else class="redesign-table list-select">
        <div class="is-zebra">
            <div class="row" v-for="row in rows" v-bind:key="row.id">
                <div class="cell is-full">
                    <label class="checkbox">
                        <input type="checkbox" :value="row" v-model="selected">
                        {{customLabel(row)}}
                    </label>
                </div>
            </div>
            <div class="row" v-if="rows.length === 0">
                <div class="cell is-full">Es wurde leider nichts für das Suchkriterum gefunden</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from '@components/Loading'

export default {
    props: {
        value: {
            type: [Array, Object]
        },

        api: {
            type: String,
            required: true
        },

        params: {
            type: Object,
        },

        customLabel: {
            type: Function,
            default: function (row) {
                return row.name
            }
        }
    },

    data: function () {
        return {
            isLoading: false,
            rows: [],
            lastApiFetched: null,
            items: 0
        }
    },

    computed: {
        selected: {
            get: function () {
                return this.rows.filter(row => this.value.some(value => value.id === row.id))
            },
            set: function (values) {
                this.$emit('input', values)
            }
        }
    },

    methods: {
        search: function () {
            this.isLoading = true;
            this.rows = [];
            this.lastApiFetched = this.api;

            axios.get(`/api/${this.api}`, {
                params: this.params
            }).then(response => {
                this.isLoading = false;
                this.rows = response.data;
                this.selected = response.data;
                this.items = Number(response.headers['x-trav-total-items'])
                this.$emit('updateItems', this.items);
            });
        },
    },

    watch: {
        params: {
            immediate: true,
            deep: true,
            handler: function () {
                this.search()
            }
        },

        api: function (newApi) {
            if (this.lastApiFetched !== newApi) {
                this.search()
            }
        }
    },

    components: {
        Loading
    }
}
</script>
