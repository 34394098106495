<template>
    <div class="field level-left">
        <span :class="{'level-item': hasSlot}">
            <slot />
        </span>
        <input type="checkbox" v-model="checked" class="switch" :id="_uid" :true-value="100" :false-value="0">
        <label :for="_uid">
            <i class="fa fa-check"></i>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },

        trueValue: {
            default: true
        },

        falseValue: {
            default: false,
        }
    },

    computed: {
        checked: {
            get() { return this.value },
            set(value) { this.$emit('input', value) }
        },

        hasSlot: function() {
            return !!this.$slots.default
        }
    }
}
</script>

<style>

</style>
