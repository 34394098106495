var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-full-width" },
    [
      !_vm.readOnly
        ? _c("multiselect", {
            attrs: {
              options: _vm.bankAccounts,
              "internal-search": false,
              "track-by": "id",
              label: "name",
              placeholder: "Konto auswählen",
              "allow-empty": false,
              multiple: false,
            },
            model: {
              value: _vm.bankAccount,
              callback: function ($$v) {
                _vm.bankAccount = $$v
              },
              expression: "bankAccount",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.readOnly
        ? _c("span", { staticStyle: { "padding-left": "6px" } }, [
            _vm._v(_vm._s(_vm.value ? _vm.value.name : "")),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }