var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-wrapper" },
    [
      _c("div", { staticClass: "control" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.showMessage
        ? _c(
            "popover",
            {
              staticClass: "is-error",
              attrs: { trigger: "hover", container: false },
            },
            [
              _vm.showMessage
                ? _c("i", { staticClass: "material-icons" }, [_vm._v("error")])
                : _vm._e(),
              _vm._v(" "),
              _c("template", { slot: "popover" }, [
                _vm._v(_vm._s(_vm.message)),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }