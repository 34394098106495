<template>
    <div v-click-outside="onBlur"  class="tiptap" :class="{'is-alone' : !isNotAlone, 'is-not-alone' : isNotAlone, 'is-fullscreen': fullscreen, resizeable, 'is-disabled': !editable}">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
            <div class="tiptap-menu" :class="{'is-not-alone' : isNotAlone}">
                <button v-if="hasMenu('fullscreen')" type="button" :class="{ 'is-active': fullscreen }" @click="toggleFullScreen()">
                    <i class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</i>
                </button>
                <template v-if="!codeView">
                    <button v-if="hasMenu('bold')" type="button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                        <i class="material-icons">format_bold</i>
                    </button>
                    <button v-if="hasMenu('italic')" type="button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                        <i class="material-icons">format_italic</i>
                    </button>
                    <button v-if="hasMenu('underline')" type="button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                        <i class="material-icons">format_underline</i>
                    </button>
                    <button
                        v-if="hasMenu('h1')"
                        type="button"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                        @click="commands.heading({ level: 1 })"
                    ><span>H1</span></button>
                    <button
                        v-if="hasMenu('h2')"
                        type="button"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click="commands.heading({ level: 2 })"
                    ><span>H2</span></button>
                    <button
                        v-if="hasMenu('bullet_list')"
                        type="button"
                        :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                        <i class="material-icons">format_list_bulleted</i>
                    </button>
                    <button v-if="hasMenu('ordered_list')" type="button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                        <i class="material-icons">format_list_numbered</i>
                    </button>
                    <!--<button v-if="hasMenu('link')" type="button" :class="{ 'is-active': isActive.link() }" @click="showLinkMenu(getMarkAttrs('link'))">
                        <i class="material-icons">insert_link</i>
                    </button>-->
                    <button v-if="hasMenu('table')" type="button" :class="{ 'is-active': isActive.table() }" @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })">
                        <i class="material-icons">border_all</i>
                    </button>
                </template>
                <button v-if="hasMenu('code')" type="button" :class="{'is-active': codeView}" @click="toggleCodeView()">
                    <i class="material-icons">code</i>
                </button>

                <input-color v-if="hasMenu('color')" @input="color => commands.textcolor({ color })" />

                <button v-if="hasMenu('undo')" type="button" @click="commands.undo">
                    <i class="material-icons">undo</i>
                </button>

<!--                <div class="tiptap-submenu" :class="{ 'is-active': isActive.table() }">
                    <button type="button" @click="commands.deleteTable" title="Delete tabl">
                        <delete-table-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.addColumnBefore" title="Add column before">
                        <add-col-before-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.addColumnAfter" title="Add column after">
                        <add-col-after-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.deleteColumn" title="Delete column">
                        <delete-row-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.addRowBefore" title="Add row before">
                        <add-row-before-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.addRowAfter" title="Add row after">
                        <add-row-after-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.deleteRow" title="Delete row">
                        <delete-row-icon class="svg-inline" />
                    </button>
                    <button type="button" @click="commands.toggleCellMerge" title="Toggle cell merge">
                        <combine-cells-icon class="svg-inline" />
                    </button>
                </div>-->

                <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <input class="input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" @blur="hideLinkMenu" />
                    <button @click="setLinkUrl(commands.link, null)">
                        <i class="material-icons">close</i>
                    </button>
                </form>
            </div>
        </editor-menu-bar>

        <div v-if="codeView" class="tiptap-content">
            <pre>{{ html }}</pre>
        </div>
        <editor-content v-else class="tiptap-content" :editor="editor" ref="content"/>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import vClickOutside from 'v-click-outside';
import {
    Bold,
    Italic,
    Underline,
    Heading,
    ListItem,
    BulletList,
    OrderedList,
    Link,
    History,
    Table,
    TableHeader,
    TableCell,
    TableRow,
    HardBreak
} from 'tiptap-extensions'

import debounce from 'lodash/debounce'
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { getStorageSize, setStorageSize } from '@utilities/functions';

import { InputColor } from '@components/form'
import TextColor from './TextColor'


/*import AddColAfterIcon from '@icons/add_col_after.svg?inline'
import AddColBeforeIcon from '@icons/add_col_before.svg?inline'
import AddRowAfterIcon from '@icons/add_row_after.svg?inline'
import AddRowBeforeIcon from '@icons/add_row_before.svg?inline'
import CombineCellsIcon from '@icons/combine_cells.svg?inline'
import DeleteColIcon from '@icons/delete_col.svg?inline'
import DeleteRowIcon from '@icons/delete_row.svg?inline'
import DeleteTableIcon from '@icons/delete_table.svg?inline'*/


export default {
    props: {
        value: {
            required: true,
            //validator: prop => typeof prop === 'string' || prop === null
        },

        preview: {
            default: ''
        },

        menuItems: {
            type: Array,
            default: () => ['fullscreen', 'bold', 'italic', 'underline', 'h1', 'h2', 'bullet_list', 'ordered_list', 'link', 'table', 'color', 'undo'],
        },

        resizeable: {
            type: Boolean,
            default: true,
        },

        isNotAlone: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: true,
        },

        defaultSize: {
            type: Number,
            default: null,
        }
    },

    components: {
        EditorContent,
        EditorMenuBar,

        InputColor,

        // Icons
  /*      AddColAfterIcon,
        AddColBeforeIcon,
        AddRowAfterIcon,
        AddRowBeforeIcon,
        CombineCellsIcon,
        DeleteColIcon,
        DeleteRowIcon,
        DeleteTableIcon*/
    },

    data() {
        return {
            // Create an `Editor` instance with some default content. The editor is
            // then passed to the `EditorContent` component as a `prop`
            editor: new Editor({
                content: this.preview ? this.preview : this.value,
                editable: this.editable,
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Heading({
                        levels: [1, 2],
                    }),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new Link(),
                    new History(),
                    new Table({
                        resizable: true,
                    }),
                    new HardBreak(),
                    new TableHeader(),
                    new TableCell(),
                    new TableRow(),
                    new TextColor()
                ],
                onUpdate: ({ getHTML }) => {
                    // get new content on update
                    const newContent = getHTML();
                    this.$emit('input', newContent);
                    this.html = newContent
                }
            }),

            linkUrl: null,
            linkMenuIsActive: false,
            fullscreen: false,
            codeView: false,
            html: this.value,
            hasFocus: false,
        }
    },

    methods: {
        onFocus: function() {
            if (!this.hasFocus) {
                this.hasFocus = true;
                this.$emit('focus')
                if (this.preview !== '' || this.value === '') {
                    this.editor.setContent(this.value);
                }
            }
        },

        onBlur: function() {
            if (this.hasFocus) {
                this.hasFocus = false;
                this.$emit('blur')

                if (!['', null].includes(this.preview)) {
                    this.editor.setContent(this.preview);
                }
            }
        },

        hasMenu: function(item) {
            return this.menuItems.includes(item) ;
        },

        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },

        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false
        },

        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu()
        },

        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            this.resizeable = !this.fullscreen;
        },

        toggleCodeView() {
            this.codeView = !this.codeView
        },

        updateHeight: debounce(function(height) {
            this.$emit('resize', height);
            if(!this.defaultSize){
                setStorageSize(window.location.href, height)
            }
        }, 1000)
    },

    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy()
    },

    mounted() {
        if (this.resizeable) {
            let height = getStorageSize(window.location.href) ? getStorageSize(window.location.href) : 100;

            if(this.defaultSize){
                height = this.defaultSize;
            }
            const element = this.$refs.content.$el;

            this.$nextTick(() => {
                if (height !== null) {
                    element.style.height = `${height}px`;
                }
                new ResizeSensor(element, () => {
                    this.updateHeight(element.clientHeight)
                });
            })
        }
        this.$el.addEventListener('focusin', this.onFocus);
    },

    watch: {
        value: function (value) {
            if ((this.value !== value || this.editor.getHTML() !== value) && (this.hasFocus || !this.preview)) {
                this.editor.setContent(this.value);
            }
        },
        preview: function (value) {
            if ((this.preview !== value || value !== this.editor.getHTML()) && !this.hasFocus) {
                this.editor.setContent(value);
            }
        },
        editable() {
            this.editor.setOptions({
                editable: this.editable,
            })
        },
    },
    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>
