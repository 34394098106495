<template>
    <modal :visible="visible" class="is-form" v-on:closeModal="close">
        <p slot="header">{{label}}</p>
        <template slot="content">
            <div  class="images columns is-multiline" v-if="images.length > 0">
                <div
                    class="column is-one-third"
                    v-for="image in images"
                    v-bind:key="image.id"
                >
                    <figure
                        v-on:click.prevent.stop="selectImage(image)"
                        :class="{'is-active': isSelected(image)}"
                        class="image"
                    >
                        <img :src="image.publicPath" :alt="image.title">
                    </figure>

                    <span v-if="image.title" class="is-truncated" :title="image.title">{{image.title}}</span>
                </div>
            </div>
            <p class="notification" v-else>Keine Bilder verfügbar</p>
        </template>

        <div class="level" slot="footer">
            <button class="button is-cancel" v-on:click.prevent.stop="close">Abbrechen</button>
            <div v-if="!multiple"></div>
            <button v-else class="button is-primary" v-on:click.prevent="submitSelection">Speichern</button>
        </div>
    </modal>
</template>

<script>
import { getImages } from '@api';
import Modal from '@components/Modal';

export default {
    props: {
        params: {
            type: Object,
        },

        value: {},
        label: {
            type: String,
            default: 'Bild von den Stammdaten auswählen'
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            images: [],
            selectedImages: [],
            visible: false,
        }
    },

    methods:{
        open: function () {
            this.visible = true;

            getImages(this.params)
                .then(response => {
                    this.images = response.data
                });

            if (this.multiple) {
                this.selectedImages = JSON.parse(JSON.stringify(this.value))
            }
        },

        close: function () {
            this.visible = false;
        },

        selectImage: function (image) {
            if (this.multiple) {
                if (this.isSelected(image)) {
                    this.selectedImages = this.selectedImages.filter(({id}) => id !== image.id)
                } else {
                    this.selectedImages = [...this.selectedImages, image]
                }
            } else {
                this.$emit('input', image);
                this.close();
            }
        },

        submitSelection: function() {
            this.$emit('input', this.selectedImages);
            this.close()
        },

        isSelected(image) {
            if (this.multiple) {
                return this.selectedImages.map(image => image.id).includes(image.id)
            } else {
                return !!this.value && image.id === this.value.id
            }
        }
    },

    components: {
        Modal
    }
}
</script>

