<script>
import AsyncSelect from './AsyncSelect';

export default {
    mixins: [ AsyncSelect ],


    props: {
        crudLinkTitle: { type: String, default: 'Neuen Ort hinzufügen' }
    },


    placeholder: 'Place wählen',
    api: 'places'
}
</script>
