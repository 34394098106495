<template>
    <multiselect
        class               = "is-order-select"
        v-model             = "orderSelected"
        v-bind:options      = "orders"
        v-bind:custom-label = "orderLabel"
        v-bind:multiple     = "multiple"

        v-bind:loading          = "isLoading"
        v-bind:internal-search  = "false"

        track-by    = "id"
        v-bind:placeholder = "placeholder"

        v-on:search-change = "orderSearch"
    >
        <template slot="option" slot-scope="props">
            <span class="option__id" v-bind:title="'#' + props.option.id"># {{ props.option.id }}</span>

            <span class="option__name" v-bind:title="props.option.client.companyName"><i class="material-icons">perm_identity</i> {{ props.option.client.companyName }} <template v-if="props.option.groupName"> ({{ props.option.groupName }})</template></span>

            <span class="option__destination" v-bind:title="getDestinations(props.option)"><i class="material-icons">flag</i> {{ getDestinations(props.option) }}</span>

            <span class="option__date"><i class="material-icons">date_range</i> {{ props.option.startAt | dayMonth }} - {{ props.option.endAt | fullYear }}</span>
        </template>
        <span slot="caret" v-if="orders.length === 0"></span>
    </multiselect>
</template>



<script>
import debounce from 'lodash/debounce';
import { getOrders } from '@api';
import { notifySuccess, notifyError } from '@components/Notification';
import Multiselect from './Multiselect';
import { dayMonth, fullYear } from '@utilities/functions';
import mixins from "@orders/placeholders/components/forms/mixins";
import axios from "axios";


export default {
    mixins: [mixins],

    props: {
        value:       {},
        placeholder: { type: String, default: 'Reise suchen' },
        multiple:    { tpye: Boolean, default: false },
        queryString: {type: String, default: '&_groups[]=order_list_read&_groups[]=order_read_extended_contacts&_groups[]=contact_read&_groups[]=contact_read_relations&_groups[]=provider_read_list&_groups[]=agency_read_list'}
    },


    computed: {
        orderSelected: {
            get: function () { return this.value },
            set: function (ordersSelected) { this.$emit('input', ordersSelected )}
        }
    },


    data: function () {
        return {
            orders: [],
            isLoading: false
        }
    },


    methods: {
        orderLabel: function (order) {
            return '# ' + order.orderNumber +
                    ' - ' + order.client.companyName +
                    ' - ' + dayMonth(order.startAt) + ' - ' + fullYear(order.endAt);
        },


        orderSearch: debounce(function (query) {
            if (query) {
                let queryString = '?_search=' + query;

                // For getting basic info about the order (which by default is all closed)
                queryString += this.queryString;

                this.isLoading = true;
                this.callCancelRequest();
                let cancelToken = this.cancelSource.token;

                getOrders(queryString, {}, cancelToken).then(response => {
                        this.orders = response.data;
                }, error => {
                    if(!axios.isCancel(error)) {
                        notifyError('Die Reisen konnten nicht geladen werden! Server error!');
                    }
                })
                    .then(() => { this.isLoading = false; });
            }
        }, 1000),


        getDestinations: function (order) {
            return order.trip.destinations.map(item => item.name).join(', ');
        }
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        Multiselect
    }
}
</script>
