<script>
import { VPopover } from 'v-tooltip'
export default {
    extends: VPopover,

    props: {
        delay: {
            type: [String, Number, Object],
            default: () => {
                return {
                    show: 1000,
                    hide: 0
                }
            },
        }
    }
}
</script>
