<template>
    <div />
</template>

<script>
import Pickr from '@simonwep/pickr';

export default {
    props: {
        value: {
            type: String,
        },

        swatches: {
            type: Array,
            default: () => ([
                'rgba(244, 67, 54, 1)',
                'rgba(233, 30, 99, 1)',
                'rgba(156, 39, 176, 1)',
                'rgba(103, 58, 183, 1)',
                'rgba(63, 81, 181, 1)',
                'rgba(33, 150, 243, 1)',
                'rgba(3, 169, 244, 1)',
                '#60A4AB',
                'rgba(0, 150, 136, 1)',
                'rgba(76, 175, 80, 1)',
                'rgba(139, 195, 74, 1)',
                'rgba(205, 220, 57, 1)',
                'rgba(255, 235, 59, 1)',
                'rgba(255, 193, 7, 1)'
            ])
        },

        preview: {
            type: Boolean,
            default: true,
        },

        opacity: {
            type: Boolean,
            default: false,
        },

        hue: {
            type: Boolean,
            default: false,
        },

        default: {
            type: String,
            default: '#60A4AB'
        },

        interaction: {
            type: Object,
            default: () => ({
                hex: true,
                rgba: false,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: true,
                clear: true,
                save: true
            })
        }
    },

    data: function() {
        return {
            pickr: null,
        }
    },

    mounted: function () {
        this.initialize()

    },

    methods: {
        initialize: function () {
            this.pickr =  Pickr.create({
                el: this.$el,
                theme: 'nano',
                swatches: this.swatches,
                default: this.value || this.default,

                components: {
                    // Main components
                    preview: this.preview,
                    opacity: this.opacity,
                    hue: this.hue,

                    // Input / output Options
                    interaction: this.interaction
                }
            })
            .on('save', (color, pickr) => {
                this.$emit('input', color.toHEXA().toString())
            })
            .on('clear', pickr => {
                this.$emit('input', null)
            })
            .on('init', pickr => {
                if (this.value) {
                    pickr.setColor(this.value, true)
                    pickr.applyColor(true)
                }
            })
        },
    },

    watch: {
        value: function(color) {
            this.pickr.setColor(color, true)
            this.pickr.applyColor(true)
        }
    }
}
</script>
