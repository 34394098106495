var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVisible,
            expression: "isVisible",
          },
        ],
        class: ["modal", _vm.classes],
      },
      [
        _c("div", { staticClass: "modal-overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "header",
            [
              _vm._t("header"),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { title: "Schließen" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.$emit("closeModal")
                    },
                  },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("content", function () {
                return [_vm._v("Ana are mere")]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("footer", [_vm._t("footer")], 2),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }