var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "level level-left",
      style: _vm.isGapLess ? "display:webkit-box;" : "",
    },
    [
      _c(
        "multiselect",
        {
          staticClass: "level-item",
          class: _vm.isGapLess ? "is-gapless" : "",
          style: !_vm.isGapLess ? "width: 190px" : "",
          attrs: {
            options: _vm.checkNumbers,
            "internal-search": false,
            placeholder: "",
            "allow-empty": false,
            taggable: true,
            multiple: false,
          },
          on: { tag: _vm.handleTag },
          model: {
            value: _vm.checkNumber,
            callback: function ($$v) {
              _vm.checkNumber = $$v
            },
            expression: "checkNumber",
          },
        },
        [
          _vm.isGapLess
            ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showPrint
        ? _c(
            "button",
            {
              staticClass: "level-item btn-edit",
              on: {
                click: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "preven",
                      undefined,
                      $event.key,
                      undefined
                    )
                  )
                    return null
                  $event.stopPropagation()
                  return _vm.print.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons is-pointer" }, [
                _vm._v("print"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }