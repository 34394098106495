<template>
    <multiselect
        v-bind:options="optionTags"
        v-model="tagSelected"
        v-bind:taggable="true"
        multiple
        v-bind:custom-label = "customTagLabel"
        track-by    = "id"
        placeholder = ""
        v-on:tag="newTag => { value.push({ name: newTag }); }"
        :class="myClass"
        :max-height="600"
    >
        <template slot="option" slot-scope="props">
            <span class="option__name" :class="props.option.class"><i class="material-icons" :class="props.option.class">{{ props.option.icon }}</i> {{ props.option.name }}</span>
        </template>
        <span slot="caret" ></span>
    </multiselect>
</template>



<script>

import Multiselect from './Multiselect';


export default {
    props: {
        value:       {},
        optionTags: {},
        myClass: {}
    },


    computed: {
        tagSelected: {
            get: function () { return this.value },
            set: function (ordersSelected) { this.$emit('input', ordersSelected )}
        }
    },



    methods: {
        customTagLabel: function ({ name }) {
            return name;
        },
    },


    components: {
        Multiselect
    }
}
</script>
