<script>
import Datepicker from 'vue-bulma-datepicker';
import { German } from "flatpickr/dist/l10n/de.js";
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';


export default {
    name: 'vue-bulma-datepicker-locale-de',


    extends: Datepicker,


    props: {
        config: {
            type: Object,
            default: () => ({
                locale: German,
                dateFormat: 'd.m.Y',
                altInput: true,
                altFormat: 'F Y',
                wrap: true,
                onReady: function (_, __, fp) {
                    fp.calendarContainer.classList.add("flatpickr-calendar--months");
                },
                onMonthChange: function (_, __, fp) {
                    const date = `01.${fp.currentMonth + 1}.${fp.currentYear}`;
                    fp.setDate(date, true, 'd.m.Y');
                },
                onYearChange: function (_, __, fp) {
                    const date = `01.${fp.currentMonth + 1}.${fp.currentYear}`;
                    fp.setDate(date, true, 'd.m.Y');
                },
                onOpen: function (_, __, fp) {
                    const date = `01.${fp.currentMonth + 1}.${fp.currentYear}`;
                    fp.setDate(date, true, 'd.m.Y');
                }
            })
        },


        placeholder: {
            type: String,
            default: 'Datum'
        },
    },


    created: function () {
        this.config.plugins = [
            ShortcutButtonsPlugin({
                button: {
                    label: 'Clear date',
                },
                onClick: (index, fp) => {
                    fp.clear();
                    fp.close();
                    this.$emit('input', null)
                }
            })
        ]
    },


    methods: {
        clearDate: function () {
            this.datepicker.clear();
            this.datepicker.close();
            this.$emit('input', null);
        },
    },

}
</script>
