<template>
    <multiselect
        v-model  = "user"
        track-by = "id"
        v-bind:custom-label    = "user => user.firstName + ' ' + user.lastName"
        v-bind:options         = "users"
        v-bind:loading         = "isLoading"
        v-bind:internal-search = "false"

        v-on:search-change = "searchUsers"
        v-on:input         = "(user) => { $emit('input', user) }"
        v-on:open          = "onOpen"
    ><span slot="caret" v-if="users.length === 0" /></multiselect>
</template>



<script>
import { store } from '@utilities/store';
import Multiselect from '@components/form/Multiselect';


export default {
    store,


    props: {
        value: { default: () => [] },
    },


    computed: {
        users: function () {
            const users = this.$store.state.users.items[this.search];
            return Array.isArray(users) ? users : [];
        },


        isLoading: function () { return this.$store.state.users.isLoading; }
    },


    data: function () {
        return {
            search: '',
            user: this.value,
        }
    },


    methods: {
        searchUsers: function (string) {
            this.search = string;
            this.$store.dispatch('searchUsers', string);
        },


        onOpen: function () {
            if (this.users.length === 0) {
                this.$store.dispatch('searchUsers', '');
            }
        }
    },


    watch: {
        value: function (newValue, oldValue) {
            if (!newValue || this.user.id !== newValue.id){
                this.user = newValue;
            }
        }
    },


    components: { Multiselect }
}
</script>
