<template>
    <div>
        <div class="columns is-multiline mb-0 mt-0" v-if="multiple && value">
            <div class="column is-one-third" v-for="image in value" :key="image.key">
                <div class="is-relative">
                    <a class="image" v-bind:href="image.publicPath" target="_blank" >
                        <img v-bind:src="image.publicPath" v-bind:alt="image.title">
                    </a>

                    <i class="material-icons image-delete" v-on:click.stop="removeImage(image)">close</i>
                </div>
            </div>
        </div>

        <div class="level-left">
            <input
                type="file"
                ref="file"
                name="user_file_upload"
                v-on:change="onFileChange($event.target.name, $event.target.files)"
                style="display:none"
            />

            <a v-if="!multiple && value" class="level-item image is-64x64" v-bind:href="value.publicPath" target="_blank" >
                <img v-bind:src="value.publicPath" v-bind:alt="value.title">
            </a>

            <div class="buttons has-addons">
                <slot name="buttons" />

                <button class="button" v-on:click.prevent="launchFilePicker()">
                    <slot name="label">
                        <i class="material-icons">photo</i>
                        {{label}}
                    </slot>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { addImage } from '@api';

export default {
    props: {
        value: {},
        label: {
            type: String,
            default: 'Upload image'
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            maxSize: 2048
        }
    },

    methods: {
        launchFilePicker(){
            this.$refs.file.click();
        },

        onFileChange(fieldName, file) {
            const { maxSize } = this
            let imageFile = file[0]

            //check if user actually selected a file
            if (file.length > 0) {
                let size = imageFile.size / maxSize / maxSize
                if (!imageFile.type.match('image.*')) {
                    // check whether the upload is an image
                    this.errorDialog = true
                    this.errorText = 'Please choose an image file'
                } else if (size>1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = 'Your file is too big! Please select an image under 2MB'
                } else {
                    // Append file into FormData & turn file into image URL
                    let formData = new FormData()
                    let imageURL = URL.createObjectURL(imageFile)
                    formData.append(fieldName, imageFile)
                    formData.append('title', imageFile.name.split('.').slice(0, -1).join('.'))

                    addImage(formData)
                        .then(response => {
                            if (this.multiple) {
                                this.$emit('input', [...this.value, response.data])
                            } else {
                                this.$emit('input', response.data)
                            }
                        }, error => {
                            console.error(error)
                        })
                }
            }
        },

        removeImage: function(image) {
            const images = this.value.filter(({id}) => id !== image.id)
            this.$emit('input', images)
        }
    }
}
</script>

