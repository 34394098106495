var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onBlur,
          expression: "onBlur",
        },
      ],
      staticClass: "tiptap",
      class: {
        "is-alone": !_vm.isNotAlone,
        "is-not-alone": _vm.isNotAlone,
        "is-fullscreen": _vm.fullscreen,
        resizeable: _vm.resizeable,
        "is-disabled": !_vm.editable,
      },
    },
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ commands, isActive, getMarkAttrs }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "tiptap-menu",
                    class: { "is-not-alone": _vm.isNotAlone },
                  },
                  [
                    _vm.hasMenu("fullscreen")
                      ? _c(
                          "button",
                          {
                            class: { "is-active": _vm.fullscreen },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleFullScreen()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.fullscreen
                                    ? "fullscreen_exit"
                                    : "fullscreen"
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.codeView
                      ? [
                          _vm.hasMenu("bold")
                            ? _c(
                                "button",
                                {
                                  class: { "is-active": isActive.bold() },
                                  attrs: { type: "button" },
                                  on: { click: commands.bold },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("format_bold"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("italic")
                            ? _c(
                                "button",
                                {
                                  class: { "is-active": isActive.italic() },
                                  attrs: { type: "button" },
                                  on: { click: commands.italic },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("format_italic"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("underline")
                            ? _c(
                                "button",
                                {
                                  class: { "is-active": isActive.underline() },
                                  attrs: { type: "button" },
                                  on: { click: commands.underline },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("format_underline"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("h1")
                            ? _c(
                                "button",
                                {
                                  class: {
                                    "is-active": isActive.heading({ level: 1 }),
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return commands.heading({ level: 1 })
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("H1")])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("h2")
                            ? _c(
                                "button",
                                {
                                  class: {
                                    "is-active": isActive.heading({ level: 2 }),
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return commands.heading({ level: 2 })
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("H2")])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("bullet_list")
                            ? _c(
                                "button",
                                {
                                  class: {
                                    "is-active": isActive.bullet_list(),
                                  },
                                  attrs: { type: "button" },
                                  on: { click: commands.bullet_list },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("format_list_bulleted"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("ordered_list")
                            ? _c(
                                "button",
                                {
                                  class: {
                                    "is-active": isActive.ordered_list(),
                                  },
                                  attrs: { type: "button" },
                                  on: { click: commands.ordered_list },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("format_list_numbered"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasMenu("table")
                            ? _c(
                                "button",
                                {
                                  class: { "is-active": isActive.table() },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return commands.createTable({
                                        rowsCount: 3,
                                        colsCount: 3,
                                        withHeaderRow: false,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("border_all"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasMenu("code")
                      ? _c(
                          "button",
                          {
                            class: { "is-active": _vm.codeView },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleCodeView()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("code"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasMenu("color")
                      ? _c("input-color", {
                          on: {
                            input: (color) => commands.textcolor({ color }),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasMenu("undo")
                      ? _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: { click: commands.undo },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("undo"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.linkMenuIsActive
                      ? _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.setLinkUrl(
                                  commands.link,
                                  _vm.linkUrl
                                )
                              },
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.linkUrl,
                                  expression: "linkUrl",
                                },
                              ],
                              ref: "linkInput",
                              staticClass: "input",
                              attrs: { type: "text", placeholder: "https://" },
                              domProps: { value: _vm.linkUrl },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "esc",
                                      27,
                                      $event.key,
                                      ["Esc", "Escape"]
                                    )
                                  )
                                    return null
                                  return _vm.hideLinkMenu.apply(null, arguments)
                                },
                                blur: _vm.hideLinkMenu,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.linkUrl = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.setLinkUrl(commands.link, null)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("close"),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.codeView
        ? _c("div", { staticClass: "tiptap-content" }, [
            _c("pre", [_vm._v(_vm._s(_vm.html))]),
          ])
        : _c("editor-content", {
            ref: "content",
            staticClass: "tiptap-content",
            attrs: { editor: _vm.editor },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }