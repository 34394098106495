var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-form",
      attrs: { visible: _vm.visible },
      on: { closeModal: _vm.close },
    },
    [
      _c("p", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _c("template", { slot: "content" }, [
        _vm.images.length > 0
          ? _c(
              "div",
              { staticClass: "images columns is-multiline" },
              _vm._l(_vm.images, function (image) {
                return _c(
                  "div",
                  { key: image.id, staticClass: "column is-one-third" },
                  [
                    _c(
                      "figure",
                      {
                        staticClass: "image",
                        class: { "is-active": _vm.isSelected(image) },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.selectImage(image)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: image.publicPath, alt: image.title },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    image.title
                      ? _c(
                          "span",
                          {
                            staticClass: "is-truncated",
                            attrs: { title: image.title },
                          },
                          [_vm._v(_vm._s(image.title))]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _c("p", { staticClass: "notification" }, [
              _vm._v("Keine Bilder verfügbar"),
            ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button is-cancel",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_vm._v("Abbrechen")]
          ),
          _vm._v(" "),
          !_vm.multiple
            ? _c("div")
            : _c(
                "button",
                {
                  staticClass: "button is-primary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitSelection.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Speichern")]
              ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }