var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buttons" },
    _vm._l(12, function (n) {
      return _c(
        "button",
        {
          key: n,
          staticClass: "button",
          class: { "is-primary": _vm.isSelected(n) },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.selectMonth(n)
            },
          },
        },
        [_vm._v(_vm._s(n))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }