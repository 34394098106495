var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrap ? "WrapperInput" : "SingleInput",
    {
      ref: "datePicker",
      tag: "component",
      attrs: {
        value: _vm.date,
        placeholder: _vm.placeholder,
        inputClass: _vm.inputClass,
        alignment: _vm.alignment,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }