<template>
    <tbody>
        <tr>
            <th>
                <label class="label">
                    <template v-if="isRouteMap">Start:</template>
                    <template v-else>Suchen:</template>
                </label>
            </th>
            <td>
                <div class="level level-left">
                    <div class="level-item control">
                        <input-place v-model="location" v-on:place_changed="updateGoogleMap" />
                    </div>
                    <i v-if="!isRouteMap" class="material-icons is-pointer" v-on:click="isRouteMap = true">add_circle</i>
                </div>
            </td>
        </tr>

        <template v-if="isRouteMap">
            <tr>
                <th><label class="label">Ziel:</label></th>
                <td>
                    <div class="level level-left">
                        <div class="level-item control">
                            <input-place v-model="location2" v-on:place_changed="updateRouteCoordinates" />
                        </div>
                        <i class="material-icons is-pointer" v-on:click="deleteRouteCoordinates">delete</i>
                    </div>
                </td>
            </tr>

            <tr>
                <th><label class="label">Mode:</label></th>
                <td>
                    <toggle-switch v-model="mode">
                        <option v-for="mode in modes" v-bind:key="mode.key" v-bind:value="mode.key">
                            <i class="material-icons">{{mode.icon}}</i>
                        </option>
                    </toggle-switch>
                </td>
            </tr>
        </template>

        <tr>
            <th class="is-top"><label class="label">Preview:</label></th>
            <td>
                <map-preview
                    v-bind="googleMapData"

                    v-on:zoom="value => zoom = value"
                    v-on:pan="handlePan"
                    v-on:position="handlePosition"

                    ref="map"
                />
                <span v-if="isRouteMap" class="help">Die Verbindung zwischen den beiden Orten wird erst beim Speichern generiert</span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import has from 'lodash/has'
import ToggleSwitch from '@components/form/ToggleSwitch'
import InputPlace from '@components/form/InputPlace'
import MapPreview from '@components/Map';

export default {
    props: {
        options: {
            type: Object,
            required: true,
            default: () => ({
                googleMapModes: []
            })
        },

        value: {}
    },

    components: {
        ToggleSwitch, InputPlace, MapPreview
    },

    computed: {
        mode: {
            get: function () { return this.value.mode },
            set: function (mode) { this.update({ mode }) }
        },

        modes: function () {
            return this.options && this.options.googleMapModes
                ? this.options.googleMapModes.map(mode => ({
                    ...mode,
                    icon: {
                        'driving': 'directions_car',
                        'walking': 'directions_walk',
                        'cycling': 'directions_bike',
                        'transit': 'directions_transit'
                    }[mode.key]
                }))
                : []
        },

        location: {
            get() { return this.value.location },
            set(location) { this.update({ location }) }
        },

        location2: {
            get() { return this.value.location2 },
            set(location2) { this.update({ location2 }) }
        },

        isRouteMap: {
            get() { return this.value.isRouteMap },
            set(isRouteMap) { this.update({ isRouteMap }) }
        },

        zoom: {
            get() { return this.value.zoom },
            set(zoom) { this.update({ zoom }) }
        },

        googleMapData: function () {
            const { latitude, longitude, latitude2, longitude2, centerLatitude, centerLongitude, zoom } = this.value;

            return {
                lat: Number(latitude),
                lng: Number(longitude),
                ...!!latitude2 && { lat2: Number(latitude2)},
                ...!!longitude2 && { lng2: Number(longitude2)},
                centerLat: Number(centerLatitude),
                centerLng: Number(centerLongitude),
                zoom: Number(zoom),
            }
        },
    },

    methods: {
        updateGoogleMap: function ({lat, lng}) {
            this.update({
                longitude: lng,
                latitude: lat,
                centerLongitude: lng,
                centerLatitude: lat,
            })

            this.$nextTick(() => {
                this.$refs.map.setCenter()
            })
        },

        updateRouteCoordinates: function ({lat, lng}) {
            this.update({
                longitude2: lng,
                latitude2: lat,
            })
        },

        deleteRouteCoordinates: function () {
            this.update({
                isRouteMap: false,
                longitude2: null,
                latitude2: null
            })
        },

        handlePan: function ({lat, lng}) {
            this.update({
                centerLongitude: lng,
                centerLatitude: lat,
            })
        },

        handlePosition: function (position) {
            if (has(position, 'lat')) {
                const { lat, lng } = position
                this.update({
                    longitude: lng,
                    latitude: lat,
                })
            } else if (has(position, 'lat2')) {
                const { lat2, lng2 } = position
                this.update({
                    longitude2: lng2,
                    latitude2: lat2,
                })
            }
        },

        update: function(obj) {
            this.$emit('input', {
                ...this.value,
                ...obj
            })
        }
    }
}
</script>
